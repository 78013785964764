import React, { useEffect, useState } from "react";
import { Button, TextField } from "components";
import { useTranslation } from "react-i18next";
import TextArea from "components/FormElements/Input/TextArea";
import CheckBox from "components/FormElements/CheckBox";
import ComboBox from "components/FormElements/ComboBox";
import {
  getTicketCategoriesList,
  getTicketDepartmentsList,
  getTicketOwnedProductList,
  ticketAddAttachment,
  ticketAddFile,
  ticketCreate
} from "services/allServices";
import { useComboBox } from "hooks";
import FileUploader from "components/FileUploader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AlertDanger from "components/ContentAlerts/AlertDanger";
import { useAuthStore } from "store/AuthStore";
import { useGlobalStore } from "store/GlobalStore";
import { useToasts } from "react-toast-notifications";

const schema = yup.object().shape({
  title: yup.string().required(),
  desc: yup.string().required().min(10).max(1000)
});

function SupportRequestForm({ setOpenModal, setSupportRequestType, defaultProduct }) {
  const { t, i18n } = useTranslation(["projects", "global", "support"]);
  const { addToast } = useToasts();
  const [permissions] = useGlobalStore(state => [state.permissions]);
  const [openAlert, setOpenAlert] = useState(false);
  const [file, uploadFile] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [selectedDep, depList, changeSelectedDep, setDepList] = useComboBox(
    t("support:selectDepartment")
  );
  const [selectedCat, catList, changeSelectedCat, setCatList] = useComboBox(
    t("support:selectCategory")
  );


  const onSelectedDep = (val) => {
    getTicketCategoriesList(val.id).then((cats) => {
      changeSelectedCat(0, t("support:selectCategory"));
      setCatList(cats, "id", "ticket_categories");
    });
  };
  const [allProduct, setAllProduct] = useState(false);

  const [
    selectedProduct,
    productList,
    changeSelectedProduct,
    setProductList
  ] = useComboBox(t("support:selectService"));
  const onSubmit = async (data) => {
    if (
      selectedDep.id !== 0 &&
      selectedCat.id !== 0
    ) {

      let findProduct = "";
      if (allProduct) {
        findProduct = allProduct?.find((item) => item.owned_product_id === selectedProduct.id)?.type_group_info?.type_id;
      }
      let configure = {
        department_id: selectedDep.id,
        category_id: selectedCat.id,
        related_product_id: selectedProduct.id === 0 ? null : selectedProduct.id,
        title: data.title,
        content: data.desc,
        is_concealed: isCheck,
        email_notification: true,
        product_type_id: findProduct ? findProduct : null
      };

      setDisableButton(true)
      await ticketCreate(configure).then((res) => {
        if (file) {
          for (const [key, value] of Object.entries(file)) {
            let params = new FormData();
            params.append("file", value);
            ticketAddFile(params).then(url => {
              let conf = {
                ticket_id: res.ticket_id,
                reply_id: res.reply_id,
                file_path: url
              };

              if (url?.message == "unsupported_file_size") {
                setDisableButton(false)
                return addToast("10 MB dan büyük boyutlu dosya yükleyemezsiniz.", {
                  appearance: "error",
                  autoDismiss: true
                });
              }

              if (url?.message == "unsupported_file_type") {
                setDisableButton(false)
                return addToast("Sadece JPG, JPEG, PNG, PDF, RAR, ZIP ve Text uzantılı dosyalar yükleyebilirsiniz. ", {
                  appearance: "error",
                  autoDismiss: true
                });
              }

              ticketAddAttachment(conf);

            });
          }
        }
      });
      setDisableButton(false)
      setOpenModal(false);
    } else {
      setDisableButton(false)
      setOpenAlert(true);
    }
  };
  const translateDepartmentName = (name, lang) => {
    if (lang === 'tr') return name;
    switch (name) {
      case 'Muhasebe':
        return 'Accounting';
      case 'Teknik':
        return 'Technical';
      case 'Satış':
        return 'Sales';
      default:
        return name;
    }
  };
  useEffect(() => {
    const lang = localStorage.getItem('ln');

    getTicketDepartmentsList().then((deps) => {
      if (permissions?.length > 0) {
        let newList = [];
        deps?.map((item) => {
          const translatedName = translateDepartmentName(item?.department_name, lang);
          if (translatedName === "Muhasebe" && permissions?.find((item) => item.id === "support-request-accounting")?.checked === true) {
            newList.push({ ...item, department_name: translatedName })
          }
          if (translatedName === "Teknik" && permissions?.find((item) => item.id === "support-request-tech")?.checked === true) {
            newList.push({ ...item, department_name: translatedName })
          }
          if (translatedName === "Satış" && permissions?.find((item) => item.id === "support-request-sales")?.checked === true) {
            newList.push({ ...item, department_name: translatedName })
          }
        })
        setDepList(newList, "id", "department_name");
      } else {
        const translatedDeps = deps.map((item) => ({
          ...item,
          department_name: translateDepartmentName(item.department_name, lang),
        }));

        setDepList(translatedDeps, "id", "department_name");

      }

    });
    getTicketOwnedProductList().then((products) => {
      setProductList(products, "owned_product_id", "product_name");
      setAllProduct(products);
      if (defaultProduct) {
        let findProduct = products?.find((item) => item.owned_product_id === defaultProduct)
        changeSelectedProduct(findProduct.owned_product_id, findProduct.product_name)
      }
    });
  }, []);



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-5 pt-4 pb-4">
        {openAlert && (
          <AlertDanger message="Lütfen seçmediğiniz alanları seçin." />
        )}
        <div className="grid grid-cols-2 gap-5 mt-3">
          <div className="col-span-2 lg:col-span-1">
            {depList?.length > 0 && (
              <ComboBox
                id="department"
                name="department"
                selected={selectedDep}
                setSelected={changeSelectedDep}
                list={depList}
                onSelected={(v) => onSelectedDep(v)}
                label={t("global:department")}
                isSearchable={false}
              />
            )}
          </div>
          {catList?.length > 0 && (
            <div className="col-span-2 lg:col-span-1">
              <ComboBox
                id="category"
                name="category"
                selected={selectedCat}
                setSelected={changeSelectedCat}
                list={catList}
                label={t("support:category")}
                isSearchable={false}
              />
            </div>
          )}
          {productList?.length > 0 && (

            <div className="col-span-2">
              <ComboBox
                id="hizmet"
                name="hizmet"
                selected={selectedProduct}
                setSelected={changeSelectedProduct}
                list={productList}
                label={t("global:associatedService")}
                isSearchable={true}
              />
            </div>
          )}
        </div>
        <div className="mt-5">
          <TextField
            id="title"
            name="title"
            innerRef={register}
            type="text"
            ref={null}
            label={t("support:subjectTitle")}
            placeholder={t("support:enterSubjectTitle")}
            min={20}
            maxLength={100}
          />
          {errors.title && errors.title.type === "required" && (
            <span className="text-alert-red">{t("support:enterSubjectAlert")}</span>
          )}

        </div>
        <div className="mt-5">
          <TextArea
            name="desc"
            label={t("support:ticketContent")}
            placeholder={t("support:enterTicketContent")}
            rows="4"
            innerRef={register}
          />
          {errors.desc && errors.desc.type === "required" && (
            <span className="text-alert-red">
              {t("support:ticketContentAlert")}
            </span>
          )}
          {errors.desc && errors.desc.type === "min" && (
            <span className="text-alert-red">
              {t("support:10CharAlert")}
            </span>
          )}
          {errors.desc && errors.desc.type === "max" && (
            <span className="text-alert-red">
              {t("support:1000CharAlert")}
            </span>
          )}
        </div>
        <div className="grid grid-cols-7 gap-5">
          <div className="col-span-7 lg:col-span-5">
            <FileUploader
              id="download1"
              file="fileUpload1"
              name="download1"
              uploadId="uploadId1"
              value={file}
              defaultText="Dosya Seçiniz"
              required="required"
              setValue={uploadFile}
              label={t("support:uploadFile")}
              placeholder="Dosya seçiniz"
              inputStyle="w-full mb-2 text-xs"
              labelStyle="mt-3 mb-0"
              multiple={true}
              maxFile={10}
            />
          </div>
          <div className="col-span-7 mt-10 lg:col-span-2">
            <CheckBox
              id="encryptAnswer"
              name="encryptAnswer"
              label={t("support:encryptAnswer")}
              onChange={() => setIsCheck(true)}
              value={isCheck}
            />
          </div>
        </div>
      </div>

      <div
        className="flex flex-col-reverse justify-end items-end px-5 py-3 bg-white rounded-md lg:flex-row lg:px-0 lg:pr-5">
        <Button
          onClick={() => {
            setOpenModal(false);
            setSupportRequestType("1");
          }}
          className="px-12 mt-3 mr-0 w-full lg:mt-0 lg:mr-5 lg:w-auto"
          tertiary
        >
          {t("global:giveUp")}
        </Button>
        <Button disabled={disableButton} type="submit" className="px-12 mr-0 w-full lg:w-auto" primary>
          {t("global:apply")}
        </Button>
      </div>
    </form>
  );
}

export default SupportRequestForm;
