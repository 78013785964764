import Button from "components/FormElements/Button";
import { useTranslation } from "react-i18next";

const ExampleCSVModal = ({ setDownloadExcelModal, downloadExampleCSV }) => {
    const { t } = useTranslation(["global", "services"]);

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                ></span>
                <div
                    className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-modalBg">
                        <div className="flex justify-end px-2 py-1">
                            <i
                                onClick={() => setDownloadExcelModal(false)}
                                className="text-2xl cursor-pointer ri-close-line text-passive"
                            ></i>
                        </div>

                        <div className="flex flex-col px-5 pt-4 bg-modalBg">
                            <p className="font-bold"> Bu bölüm bilgilendirme amaçlıdır.</p>
                            <p className="py-2 font-bold"> Beyan Usulü</p>
                            <p><span className="font-bold">HS_2015</span>    Alıcı     1 Mayıs 2015 tarihi öncesinde onaylı olarak kaydedilmiştir.</p>
                            <p><span className="font-bold">HS_KARAR </span>    İzin durumu     hizmet sağlayıcının kendi isteğiyle RET olarak belirlenmiştir.</p>
                            <p className="py-2 font-bold">Fiziksel Ortam</p>
                            <p><span className="font-bold">HS_FIZIKSEL_ORTAM </span>   İzin     hizmet sağlayıcı tarafından fiziksel ortamda alınmıştır.</p>

                            <p><span className="font-bold">  HS_ISLAK_IMZA </span>  İzin     alıcının bir formu veya anketi imzalaması üzerine alınmıştır.</p>
                            <p><span className="font-bold">HS_ETKINLIK </span>   İzin     hizmet sağlayıcının düzenlediği bir etkinlikte alınmıştır..</p>
                            <p><span className="font-bold">HS_ATM </span> İzin     hizmet sağlayıcıya ait yerleşik ATM cihazıyla alınmıştır.</p>

                            <p className="py-2 font-bold">  Elektronik Ortam</p>

                            <p><span className="font-bold">HS_EORTAM  </span>  İzin     hizmet sağlayıcıya ait bir elektronik ortamda alınmıştır.</p>
                            <p><span className="font-bold"> HS_WEB </span>İzin     hizmet sağlayıcının web sitesi üzerinde yapılan bir işlemle alınmıştır.</p>
                            <p><span className="font-bold">HS_MOBIL </span> İzin     hizmet sağlayıcıya ait mobil uygulama üzerinden alınmıştır.</p>
                            <p><span className="font-bold">HS_MESAJ </span> İzin     hizmet sağlayıcıya ait kısa mesaj numarası üzerinden alınmıştır.</p>
                            <p><span className="font-bold">HS_EPOSTA </span>   İzin     hizmet sağlayıcıya ait e-posta vasıtasıyla alınmıştır.</p>
                            <p><span className="font-bold"> HS_CAGRI_MERKEZI  </span>  İzin     hizmet sağlayıcıya bağlı bir çağrı merkezinde sesle veya numara tuşlamayla alınmıştır.</p>
                            <p><span className="font-bold">HS_SOSYAL_MEDYA </span>İzin     hizmet sağlayıcıya ait sosyal medya aracı üzerinden alınmıştır.</p>
                        </div>

                        <div className="flex justify-center py-4">
                            <Button
                                onClick={() => downloadExampleCSV(true)}
                                className="px-8 mt-3 lg:mt-0"
                                green
                            >
                                <i className="ri-history-line" />
                                <span>{t("services:downloadExampleExcel")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExampleCSVModal