import { Button } from "components";
import CheckBox from "components/FormElements/CheckBox";
import TicketMessageBox from "components/TicketAndSupport/TicketMessageBox";
import TicketTextInput from "components/TicketAndSupport/TicketTextInput";
import React, { useEffect, useRef, useState } from "react";
import {
  closeTicket,
  getTicketMessages,
  ticketAddAttachment,
  ticketAddFile,
  ticketAllReply,
  ticketReplyCreate,
  ticketSingleReply
} from "services/allServices";
import { useSupportTicketStore } from "store/SupportTicketStore";
import { useNavigate } from "react-router";
import useWebSocket from "react-use-websocket";
import WarningModal from "components/Modals/WarningModal";
import TicketStaffRatingModal from "components/TicketAndSupport/TicketAndSupportModals/TicketStaffRatingModal";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

function TicketMessages() {
  const { t } = useTranslation(["support", "global"]);
  const { addToast } = useToasts();
  const [isCheck, setIsCheck] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [openStaffRatingModal, setOpenStaffRatingModal] = useState(false);

  const [allMessages, setAllMessages] = useState();
  const [isBottomScroll, setIsBottomScroll] = useState(true);

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const messagesEndRef = useRef();
  const [selectedTicketID] = useSupportTicketStore((state) => [
    state.selectedTicketID
  ]);


  //web socket stateleri
  const [ticketMessage, setTicketMessage] = useState([]);
  // const { lastJsonMessage: ticketMessage } = useWebSocket(
  //   `${process.env.REACT_APP_WEBSOCKET_URL}ticket/info/customer/?id=` + selectedTicketID +
  //   "&token=" +
  //   localStorage.getItem("__mp_tk")
  // );
  // const { lastJsonMessage: writingMessage, sendJsonMessage: sendWritingMessage } = useWebSocket(
  //   `${process.env.REACT_APP_WEBSOCKET_URL}writing/?url=` +
  //   selectedTicketID +
  //   "&token=" +
  //   localStorage.getItem("__mp_tk")
  // );


  const sendMessage = async () => {
    let configure = {
      ticket_id: allMessages.ticket.id,
      reply_content: message,
      is_concealed: isCheck
    };
    if (message !== "") {
      await ticketReplyCreate(configure).then((d) => {
        setMessage("");
        if (selectedFile) {
          for (const [key, value] of Object.entries(selectedFile)) {
            let params = new FormData();
            params.append("file", value);
            params.append("description", "ticket");
            ticketAddFile(params).then(url => {
              let conf = {
                ticket_id: d.ticket_id,
                reply_id: d.reply_id,
                file_path: url,
              };
              if (url?.message == "unsupported_file_size") {
                refreshMessages();
                return addToast("10 MB dan büyük boyutlu dosya yükleyemezsiniz.", {
                  appearance: "error",
                  autoDismiss: true
                });
              }

              if (url?.message == "unsupported_file_type") {
                refreshMessages();
                return addToast("Sadece JPG, JPEG, PNG, PDF, RAR, ZIP ve Text uzantılı dosyalar yükleyebilirsiniz. ", {
                  appearance: "error",
                  autoDismiss: true
                });
              }


              ticketAddAttachment(conf);

              setTimeout(() => {
                navigate("/ticket-and-support");

              }, 2000);
            }).catch((err) => {
              if (err.status === 413) {
                addToast("10 MB dan büyük boyutlu dosya yükleyemezsiniz.", {
                  appearance: "error",
                  autoDismiss: true
                });
              }
            })
          }
          setSelectedFile(null);

        } else {
          setTimeout(() => {
            navigate("/ticket-and-support");

          }, 2000);
        }
        bottomScroll(1);
        setSelectedFile(null);

        // getTicketMessages(selectedTicketID).then((data) => {
        //   setAllMessages(data);
        //   bottomScroll();
        //   setSelectedFile(null);
        // });
      });
    }
  };


  const bottomScroll = (type) => {
    if (messagesEndRef && type == 1) {
      messagesEndRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
    if (messagesEndRef && type == 2) {
      const scroll = messagesEndRef?.current?.scrollHeight;
      messagesEndRef.current?.scroll({ top: scroll, behavior: "smooth" });
    }

  };
  const refreshMessages = () => {
    if (selectedTicketID) {
      getTicketMessages(selectedTicketID).then((data) => {
        setAllMessages(data);
      });
      bottomScroll(1);
      setDisabledButton(true);
      setTimeout(() => {
        setDisabledButton(false);
      }, 10000);
    } else {
      navigate("/ticket-and-support");
    }
  };

  const onOpenMessages = (ticket_id, reply_id, isAll, OTP) => {
    if (isAll === false) {
      let configure = {
        ticket_id: ticket_id,
        reply_id: reply_id,
        auth_key: OTP
      };
      ticketSingleReply(configure).then((res) => {
        refreshMessages();
      });
    } else {
      ticketAllReply(ticket_id, OTP).then((res) => {
        refreshMessages();
      });
    }
  };

  const onCloseTicket = () => {
    setOpenCloseModal(false);
    closeTicket(allMessages.ticket.id).then((res) => {
      refreshMessages();
      setOpenStaffRatingModal(true);
      // navigate("/ticket-and-support");
    });
  };

  function status(status) {
    if (status?.content?.ticket_status_customer === "Açık") {
      return <p
        className="text-textGray">Açık </p>;
    } else if (status?.content?.ticket_status_customer === "Cevaplandı") {
      return <p
        className="text-textGray">Cevaplandı </p>;
    } else if (status?.content?.ticket_status_customer === "Cevapladınız") {
      return <p
        className="text-textGray">Cevapladınız </p>;
    } else if (status?.content?.ticket_status_customer === "Cevabınız Bekleniyor") {
      return <p
        className="text-textGray">Cevabınız
        Bekleniyor </p>;
    } else if (status?.content?.ticket_status_customer === "Beklemede") {
      return <p
        className="text-textGray">Beklemede </p>;
    } else if (status?.content?.ticket_status_customer === "İşleme Alındı") {
      return <p
        className="text-textGray">İşleme
        Alındı</p>;
    } else if (status?.content?.ticket_status_customer === "Kapatıldı") {
      return <p
        className="text-textGray">Kapatıldı</p>;
    } else if (status?.content?.ticket_status_customer === "Transfer Edildi") {
      return <p
        className="text-textGray">Transfer
        Edildi</p>;
    } else {
      return <p>-</p>;
    }
  }
  
  useEffect(() => {
    if (selectedTicketID) {
      getTicketMessages(selectedTicketID).then((data) => {
        setAllMessages(data);

      });
    } else {
      navigate("/ticket-and-support");
    }
  }, []);

  useEffect(() => {
    if (allMessages && isBottomScroll) {
      bottomScroll(2);
      setIsBottomScroll(false);
    }
  }, [allMessages]);

  useEffect(() => {
    if (ticketMessage !== null) {
      const setMessages = new Promise(async (resolve, reject) => {
        resolve();
      });
      setMessages.then(() => {
        // setAllMessages({
        //   attachments: ticketMessage?.attachments || allMessages?.attachments,
        //   ticket: ticketMessage?.ticket || allMessages?.ticket,
        //   ticket_replies: ticketMessage?.reply_list || allMessages?.ticket_replies,
        // });
        setTimeout(() => {
          bottomScroll(2);
        }, 100);
      });
    }
  }, [ticketMessage]);

  return (
    <div className="container flex relative flex-col justify-between h-full">
      <div className="p-3 mb-1 bg-white rounded-md border-2 shadow-sm">
        <p className="text-2xl font-semibold text-title">{allMessages?.ticket?.title}</p>
        <div className="flex space-x-3">
          <div className="flex items-center space-x-2">
            <p>{`${t("global:department")}:`}</p>
            <p className="text-textGray">{allMessages?.ticket?.department}</p>
          </div>

          <div className="flex items-center space-x-2">
            <p>{`${t("support:category")}:`}</p>
            <p className="text-textGray">{allMessages?.ticket?.category}</p>
          </div>

          <div className="flex items-center space-x-2">
            <p>{`${t("global:status")}:`}</p>
            <p className="text-textGray">{status(allMessages?.ticket?.ticket_status)}</p>
          </div>
          <div className="flex items-center space-x-2">
            <p>{`${t('associatedService')}:`}</p>
            <p className="text-textGray">{allMessages?.ticket?.product_info?.product_name}</p>
          </div>
        </div>
      </div>
      <div ref={messagesEndRef} className="overflow-y-auto p-8 w-full">
        <div className="">
          {allMessages &&
            allMessages?.ticket_replies
              .sort((a, b) => a.id - b.id)
              .map((msg, index) => {
                return (
                  <div
                    key={index}
                    className={
                      msg.reply_by_staff ? "mb-5" : "lg:flex justify-end mb-5"
                    }
                  >
                    <TicketMessageBox
                      checkID={index}
                      onOpenMessages={(ticket_id, reply_id, isAll, OTP) =>
                        onOpenMessages(ticket_id, reply_id, isAll, OTP)
                      }
                      message={msg}
                      allMessages={allMessages}
                      refreshMessages={() => refreshMessages()}

                    />
                  </div>
                );
              })}
        </div>
      </div>
      {allMessages && (allMessages?.ticket.status !== "closed" && allMessages?.ticket.status !== "transferred") && (
        <div className="sticky bottom-0 px-8 py-4 bg-white border-t-2 border-primary-hover">
          <div className="grid grid-cols-12">
            <div className="col-span-10 w-full newFileList">
              <TicketTextInput
                selectedFile={selectedFile}
                message={message}
                setMessage={(v) => {
                  setMessage(v);
                }}
                setSelectedFile={(v) => {
                  setSelectedFile(v);
                }}
                sendMessage={sendMessage}
              />
            </div>
            <div className="flex col-span-2 justify-center items-center pb-3">
              <button
                onClick={() => sendMessage()}
                className="flex items-center px-2 py-1 text-white rounded-md transition-all duration-200 bg-primary-hover lg:py-4 lg:px-3 hover:bg-primary focus:outline-none"
              >
                <i className="mr-2 ri-send-plane-line"></i>
                {t("support:sendReply")}
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center mt-3">
            <div className="flex">
              <div
                onClick={() => setOpenCloseModal(true)}
                className="flex items-center cursor-pointer text-alert-red"
              >
                <i className="mr-1 text-lg ri-close-line"></i>
                {t("support:closeTopic")}
              </div>
              <Button
                onClick={() => refreshMessages()}
                disabled={disabledButton}
                className="px-4 ml-5"
                secondary
                icon
              >
                <i className="ri-restart-line" />
                <span>{t("support:refreshMessage")}</span>
              </Button>
            </div>
            <div className="lg:mr-48">
              <CheckBox
                id="check2"
                name="check2"
                label={t("support:encryptMessage")}
                onChange={() => {
                  setIsCheck(!isCheck);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {openCloseModal &&
        <WarningModal
          title={t("support:closeTopic")}
          question={t("support:closeTopicQuestion")}
          description={t("support:closeTopicDesc")}
          buttonText={t("support:closeTopic")}
          setOpenModal={() => setOpenCloseModal(false)}
          onClick={onCloseTicket}
        />
      }

      {openStaffRatingModal && allMessages &&
        <TicketStaffRatingModal
          ticketID={allMessages?.ticket?.id}
          setOpenModal={() => setOpenStaffRatingModal(false)}

        />

      }

    </div>
  );
}

export default TicketMessages;
